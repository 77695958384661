@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@200;300;400;600&display=swap');


* {
  box-sizing: content-box !important;
  box-sizing: border-box !important;
}



body {
  font-size: 16px;
  /* font-family: 'Kanit', sans-serif !important; */
  /* font-family: 'Sarabun', sans-serif !important; */

  font-family: 'Prompt', sans-serif !important;
  color: #5f6368 !important;

}

html {
  scroll-behavior: smooth !important;

}

img {
  max-width: 100%;
  height: auto;
  align-self:flex-start;

}

a:hover {
  text-decoration: none !important;
}


/* start radio */
.progress {
  height: 0.3rem !important;
}

.radio-tile-group .input-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.radio-tile-group .input-container .radio-button {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;

}


.radio-tile-group .input-container .radio-tile.zero {

  background-color: rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 0.175);
  cursor: not-allowed;

}

.radio-tile-group .input-container .radio-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* padding: 1rem; */
  background-color: white;
  background-clip: border-box;
  /* border: 1px solid rgba(0, 0, 0, 0.175); */
  border-radius: 0.375rem;
  transition: transform 300ms ease;

}

.radio-tile-group .input-container .radio-tile-label {
  text-align: center;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  /* color: #079ad9; */
}

.radio-tile-group .input-container .radio-button:checked+.radio-tile {
  /* background-color: rgba(108, 117, 125, 1); */
  background-color: #cfe2ff;
  /*border -> #b6d4fe*/
  /* border: 1px solid rgba(108, 117, 125, 1); */
  /* border: 1px solid #bcbebf; */
  color: #084298;
}

.ant-select-selector {
  color: rgba(108, 117, 125, 0.4);
}

.form-control.search {
  border-color: rgb(230, 230, 230);
  border-radius: 50px;
}

.form-control:focus.search {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}

label.num-text {
  /* color: #034ea2; */
  display: block;
  text-align: center;
  font-size: 1.4em;
  font-weight: 600;
}

label.num-text2 {
  /* color: #034ea2; */
  display: block;
  text-align: center;
  font-size: 1em;
  font-weight: 600;
}

label.num-text-percent {
  /* color: rgba(0, 0, 0, 0.6); */
  font-size: 1em;
  font-weight: 400;
}

/* end radio */
#box-region .ant-table-wrapper table {
  text-align: center;
}

#box-region .ant-table-wrapper .ant-table-thead>tr>th {
  text-align: center;
}

#transaction .ant-table-wrapper table {
  text-align: center;
}

#transaction .ant-table-wrapper .ant-table-thead>tr>th {
  text-align: center;
}

.ant-form-item {
  margin-bottom: 0;
}

.box-region-chart {
  display: grid;
  grid-template-columns: 1fr;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.box-sale-chart {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.box-maps {
  height: 100vh;

}

.popupmap {
  display: grid;
  /* grid-template-columns: 40% 60%; */
  grid-template-columns: 100%;
  align-items: flex-start;
  width: 100%;

}

.popupphoto img {
  width: 100%;
  max-width: 150px;
  max-height: 100px;
  margin-bottom: .6em;

}

.popupdetail {
  text-align: left;
  display: block;
}

#profile .overlay {
  position: absolute;
  left: 0px;
  top: 0;
  right: 0px;
  bottom: 0;
  opacity: 0.4;
  background: #000;
}

#profile .img-profile img {
  margin: auto;
  width: 300px;
  height: 300px;
  overflow: hidden;
  object-fit: cover;
  object-position: top center;
  border-radius: 50%;
  border: 6px solid #fff;
}


.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 400px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 400px;
}

.gmap_iframe {
  height: 400px !important;
}

.classform {
  margin: 1em 0;
}

.registerbg {
  background-color: #FAF9F8;
  height: 100vh;
  padding: 12px;
  width: 100%;
}

.registerbg .table-shop .ant-table-thead>tr>th {
  text-align: center !important;
  width: 14% !important;

}

.registerlayout {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.registeruser {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: white;
  margin: 1em 0;
  border-radius: 4px;
  /* padding: 12px; */
}

.formregister {
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  width: 100%;
  background-color: white;
}
.logingrid .ant-form-item-label {
  text-align: left;
}
.heade-title-box{
  text-align: left;
  font-size: 1.3em;
  font-weight: 600;
  color: black;
  margin: 0.4em 0;
}
.select-B52Dashboard.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #ffc107;
  /* border-radius: 1em; */
}
.select-LeadPlatform.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #6c757d;
  color: white;
  /* border-radius: 1em; */
}
.select-SearchOnly.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #17a2b8;
  color: white;
  /* border-radius: 1em; */
}
.select-true.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #28a745;
  color: white;
  /* border-radius: 1em; */
}
.select-false.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #ff4d4f;
  color: white;
  /* border-radius: 1em; */
}

#profile .gallery-shop{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  text-align: center;

}

#profile .gallery-shop .img-sub-profile img {
  margin: auto;
  width: 300px;
  height: 175px;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
}

#profile .box-profile-address{
  padding: 1em 0;
}

#profile .cropmap img{
  display: flex;
  width: 400px;
  height: 350px;
  overflow: hidden;
  object-fit: cover;
  object-position: center center;
  margin: auto;
  align-items: center;
}
.nav-backend{
  display: flex;
  align-items: center;
  gap: 0.6em;
}
.btn-flushredis{
  background-color: #ffc107;
  color: black;
}

@media screen and (min-width: 1200px) {

  /*PC*/
  .h-top {
    height: 7em;
  }

  .h-top-profile {
    height: 7em;
  }

}

@media screen and (min-width: 992px) and (max-width: 1200px) {

  /*desktop*/
  .h-top {
    height: 7em;
  }

  .h-top-profile {
    height: 7em;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {

  /*Tablet*/
  .h-top {
    height: 7em;
  }

  .h-top-profile {
    height: 7em;
  }

  .box-region-chart {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  #profile .gallery-shop .img-sub-profile img {
    margin: auto;
    width: 171px;
    height: 100px;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
}
}

@media screen and (min-width: 576px) and (max-width: 768px) {

  /*mobile horizontal*/
  .h-top {
    height: 14em;
  }

  .h-top-profile {
    height: 7em;
  }

  .box-region-chart {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
}

@media screen and (max-width: 576px) {

  /*mobile vertical*/
  .h-top {
    height: 14em;
  }

  .h-top-profile {
    height: 7em;
  }

  .not-show-mobile {
    display: none;
  }

  .box-region-chart {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  #profile .gallery-shop .img-sub-profile img {
    margin: auto;
    width: 137px;
    height: 80px;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
  }

  .nav-backend{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    gap: 0.6em;
  }

}